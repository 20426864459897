import ApplicationController from './application_controller'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

export default class extends ApplicationController {
  static values = {
    content: String,
    placement: { type: String, default: 'bottom' },
    otherOptions: { type: Object, default: {} },
    minCharCount: { type: String, default: '0' },
  }

  connect() {
    this.initTippy()
  }

  disconnect() {
    this.teardownTippy()
  }

  initTippy() {
    const minContentLength = this.minCharCountValue
    const charCount = this.contentValue.length

    return charCount > minContentLength ? this.setTippy() : false
  }

  setTippy() {
    this.tippy = tippy(this.element, {
      content: this.contentValue,
      placement: this.placementValue,
      // This allows interactive: true to not cut off the tooltip.
      // https://atomiks.github.io/tippyjs/v5/faq/#my-tooltip-appears-cut-off-or-is-not-showing-at-all
      appendTo: document.body,
      ...this.otherOptionsValue,
    })
  }

  contentValueChanged(value, previousValue) {
    if (value !== previousValue && previousValue !== '') {
      if (this.tippy === undefined) {
        return
      }
      this.tippy.destroy()
      this.initTippy()
    }
  }

  teardownTippy() {
    if (this.tippy === undefined) {
      return
    }
    this.tippy.destroy()
  }
}
