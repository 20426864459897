import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['menu', 'trigger']
  // TODO: Clean up this javascript, make it less dependent on markup structure
  buildmenu() {
    var trigger = $(this.triggerTarget)
    var menu = $(this.menuTarget)
    var hasBulkAction = $(this.element).closest('tr').find('#bulk_actions_select_all')?.length > 0
    var indexOffset = hasBulkAction ? 2 : 1

    // Add options for column toggle
    if ($(menu).text().trim() == '') {
      trigger
        .closest('tr')
        .children('th:not(:last-of-type, .bulk-actions-checkbox-cell)')
        .each(function (index) {
          var textValue = $(this).text()
          var idText = `modify-column-${textValue.trim().toLowerCase()}`

          if (textValue.replace(/\s/g, '').length >= 1) {
            $(menu).append(
              $('<div class="flex py-2 px-1 truncate items-center text-left cursor-pointer rounded hover:bg-gray-100">')
                .append(
                  $('<input data-index=' + (index + indexOffset) + ' data-action="input->column-toggle#toggle">').prop({
                    type: 'checkbox',
                    class: 'h-4 w-4',
                    id: idText,
                    name: 'column_id',
                    value: textValue,
                    checked: $(this).is(':visible'),
                  })
                )
                .append(
                  $('<label>')
                    .prop({
                      for: idText,
                      class: 'ml-2 block text-sm cursor-pointer w-full',
                    })
                    .html(textValue)
                )
            )
          }
        })
    }
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max)
  }

  // Toggle show
  toggle() {
    $(event.target)
      .closest('table')
      .find('tr th:nth-child(' + parseInt(event.target.dataset.index) + ')')
      .toggle()
    $(event.target)
      .closest('table')
      .find('tr td:nth-child(' + parseInt(event.target.dataset.index) + ')')
      .toggle()
  }
}
