import 'uppy/dist/uppy.min.css'
import {
  Core,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  Dashboard,
  XHRUpload,
  AwsS3,
  AwsS3Multipart,
  ImageEditor,
  DropTarget,
} from 'uppy'
import cryptoRandomString from 'crypto-random-string'
import Cookies from 'js-cookie'
import { getUppyLocale } from '../utils/uppy'

const singleFileUpload = (fileInput, dashboardOptions = {}) => {
  const imagePreview = document.getElementById(fileInput.dataset.previewElement)
  const formGroup = fileInput.parentNode

  formGroup.removeChild(fileInput)

  const uppy = fileUpload(fileInput)

  uppy
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: imagePreview !== null ? imagePreview.parentNode : '',
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 600,
    })
    .use(Dashboard, {
      inline: true,
      target: '#drag-drop-area',
      doneButtonHandler: null,
      hideRetryButton: true,
      ...dashboardOptions,
    })
    .use(ImageEditor, { target: Dashboard })
    .use(DropTarget, { target: document.body })
  uppy.on('file-added', () => {
    formGroup.dispatchEvent(new CustomEvent('uppy:file-added', { bubbles: true }))
    $('input[type=submit]').prop('disabled', true)
  })
  uppy.on('upload', () => {
    $('input[type=submit]').prop('disabled', true)
  })
  uppy.on('upload-success', (file, response) => {
    const fileData = uploadedFileData(file, response, fileInput)

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    const hiddenInput = document.getElementById(fileInput.dataset.uploadResultElement)
    hiddenInput.value = fileData
    formGroup.dispatchEvent(new CustomEvent('uppy:upload-success', { bubbles: true }))
    $('input[type=submit]').prop('disabled', false)
  })
  uppy.on('thumbnail:generated', (file, preview) => {
    imagePreview.src = preview
  })
}

const multipleFileUpload = (fileInput, dashboardOptions = {}) => {
  const formGroup = fileInput.parentNode

  const uppy = fileUpload(fileInput)

  uppy.use(Dashboard, {
    target: formGroup,
    inline: true,
    height: 300,
    replaceTargetContent: true,
    ...dashboardOptions,
  })

  uppy.on('upload-success', (file, response) => {
    const hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    // TODO: Fix
    hiddenField.name = `album[photos_attributes][${cryptoRandomString({ type: 'alphanumeric' })}][image]`
    hiddenField.value = uploadedFileData(file, response, fileInput)

    document.querySelector('form').appendChild(hiddenField)
  })
}

const fileUpload = (fileInput) => {
  const locale = Cookies.get('locale') || 'en'
  const uppy = Core({
    id: fileInput.id,
    autoProceed: true,
    locale: getUppyLocale(locale),
    restrictions: {
      allowedFileTypes: fileInput.accept.split(','),
      // maxFileSize: 1000000,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
    },
  })

  if (fileInput.dataset.uploadServer == 's3') {
    uppy.use(AwsS3, {
      companionUrl: '/account/', // will call Shrine's presign endpoint mounted on `/s3/params`
    })
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    uppy.use(AwsS3Multipart, {
      companionUrl: '/account/', // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    })
  } else {
    uppy.use(XHRUpload, {
      endpoint: '/account/upload', // Shrine's upload endpoint
    })
  }

  return uppy
}

const uploadedFileData = (file, response, fileInput) => {
  if (fileInput.dataset.uploadServer == 's3') {
    const id = file.meta['key'].match(/^cache\/(.+)/)[1] // object key without prefix

    return JSON.stringify(fileData(file, id))
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    const id = response.uploadURL.match(/\/cache\/([^]+)/)[1] // object key without prefix

    return JSON.stringify(fileData(file, id))
  } else {
    return JSON.stringify(response.body)
  }
}

// constructs uploaded file data in the format that Shrine expects
const fileData = (file, id) => ({
  id: id,
  storage: 'cache',
  metadata: {
    size: file.size,
    filename: file.name,
    mime_type: file.type,
  },
})

export { singleFileUpload, multipleFileUpload }
