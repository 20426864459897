import ApplicationController from '../application_controller'
require('daterangepicker/daterangepicker.css')

// requires jQuery, moment, might want to consider a vanilla JS alternative
import 'daterangepicker'
import moment from 'moment'

export default class extends ApplicationController {
  static targets = ['field', 'displayField', 'clearButton']
  static values = {
    includeTime: Boolean,
    defaultStartDate: String,
    defaultEndDate: String,
    autoSubmit: Boolean,
    dateFormat: String,
    timeFormat: String,
    pickerLocale: { type: Object, default: {} },
    rangesLabels: { type: Object, default: {} },
  }

  connect() {
    this.initPluginInstance()
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  clearDate(event) {
    // don't submit the form, unless it originated from the cancel/clear button
    event.preventDefault()

    $(this.fieldTarget).val('')
    $(this.displayFieldTarget).val('')
  }

  applyDateToField(event, picker) {
    const format = this.includeTimeValue ? this.timeFormatValue : this.dateFormatValue
    const dataFormat = this.includeTimeValue ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD'

    $(this.fieldTarget).val(`${picker.startDate.format(dataFormat)}/${picker.endDate.format(dataFormat)}`)
    $(this.displayFieldTarget).val(`${picker.startDate.format(format)} - ${picker.endDate.format(format)}`)
  }

  initPluginInstance() {
    const pickerLocale = this.pickerLocaleValue
    const rangesLabels = this.rangesLabelsValue
    var localeValues = JSON.parse(JSON.stringify(pickerLocale))
    localeValues['format'] = this.includeTimeValue ? this.timeFormatValue : this.dateFormatValue
    const ranges = {}
    ranges[rangesLabels.today] = [moment(), moment()]
    ranges[rangesLabels.yesterday] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
    ranges[rangesLabels.last7Days] = [moment().subtract(6, 'days'), moment()]
    ranges[rangesLabels.last30Days] = [moment().subtract(29, 'days'), moment()]
    ranges[rangesLabels.thisMonth] = [moment().startOf('month'), moment().endOf('month')]
    ranges[rangesLabels.lastMonth] = [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ]

    let defaultStartDate = this.defaultStartDateValue !== 'false' ? moment(this.defaultStartDateValue) : moment()
    let defaultEndDate = this.defaultEndDateValue !== 'false' ? moment(this.defaultEndDateValue) : moment()
    $(this.displayFieldTarget).daterangepicker({
      timePicker: this.includeTimeValue,
      timePickerIncrement: 5,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      autoUpdateInput: true,
      locale: localeValues,
      ranges: ranges,
    })

    $(this.displayFieldTarget).on('apply.daterangepicker', this.applyDateToField.bind(this))
    if (this.autoSubmitValue === true) {
      console.log('autoSubmitting')
      $(this.displayFieldTarget).on('apply.daterangepicker', this.autoSubmitForm.bind(this))
    }
    $(this.displayFieldTarget).on('cancel.daterangepicker', this.clearDate.bind(this))

    this.pluginMainEl = this.displayFieldTarget
    this.plugin = $(this.pluginMainEl).data('daterangepicker') // weird
  }

  teardownPluginInstance() {
    if (this.plugin === undefined) {
      return
    }

    $(this.pluginMainEl).off('apply.daterangepicker')
    $(this.pluginMainEl).off('cancel.daterangepicker')

    // revert to original markup, remove any event listeners
    this.plugin.remove()
  }

  autoSubmitForm(event) {
    $(this.fieldTarget).closest('form').submit()
  }
}
