import ApplicationController from '../application_controller'

/*
 * this controller is responsible for responding to the fields--autocomplete:commit
 * and contact:created events. It will take data provided in the events and use it
 * to populate the contact card avatar, name, and email. It also coordinates
 * revealing/hiding the contact card and the search field.
 */
export default class extends ApplicationController {
  static targets = ['contact', 'searchField', 'avatar', 'name', 'email']

  revealContactCard(event) {
    const selectedData = event.detail.selected.dataset

    this.avatarTarget.src = selectedData.contactAvatarSrc
    this.nameTarget.innerText = selectedData?.contactName
    this.emailTarget.innerText = selectedData?.contactEmail

    this.contactTarget.classList.remove('hidden')
    this.searchFieldTarget.classList.add('hidden')
  }

  revealSearchField() {
    this.contactTarget.classList.add('hidden')
    this.searchFieldTarget.classList.remove('hidden')
  }

  handleContactCreated(event) {
    const selectedData = event.detail

    this.avatarTarget.src = selectedData?.avatar
    this.nameTarget.innerText = selectedData?.fullName
    this.emailTarget.innerText = selectedData?.email

    document.getElementById('order_contact_id').value = selectedData?.id

    this.contactTarget.classList.remove('hidden')
    this.searchFieldTarget.classList.add('hidden')
  }
}
