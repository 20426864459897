import ApplicationController from './application_controller'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { trackWithHeap } from '../utils/heap_tracking'

export default class extends ApplicationController {
  static values = {
    id: String,
    title: String,
    placement: { type: String, default: 'auto' },
    popperStrategy: { type: String, default: 'absolute' },
    maxWidth: { type: String, default: '350px' },
  }

  tippyInstance = null

  connect() {
    this.createTippy()
  }

  hide() {
    this.tippyInstance.hide()
  }

  show() {
    this.tippyInstance.show()
    this.track()
  }

  reveal() {
    this.isVisible() ? this.hide() : this.show()
  }

  createTippy() {
    this.tippyInstance = tippy(this.element, {
      allowHTML: true,
      animation: 'shift-away-subtle',
      arrow: false,
      placement: this.placementValue,
      content: () => {
        const id = this.idValue
        const template = document.getElementById(id)
        return template.innerHTML
      },
      interactive: true,
      theme: 'clickfunnels',
      trigger: 'click',
      maxWidth: this.maxWidthValue,
      popperOptions: {
        strategy: this.popperStrategyValue,
      },
    })
  }

  isVisible() {
    return this.tippyInstance.state.isVisible
  }

  track() {
    trackWithHeap('ContextProviderViewed', {
      tip_id: this.idValue,
      title: this.titleValue,
    })
  }
}
